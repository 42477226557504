

.segmentBtn {
    border-radius: 4px;
    background: #808080;
    color: #FFFFFF;
    padding: 1%;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: solid;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #000000;
        color: #FFFFFF;
    }
}