.ImageSegment {
    max-width: 35%;
    height: auto;
    padding-left: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.AboutText {
    text-align: center;
    position: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
}

.dualAligner {
   display: flex;
   flex-direction: row; 
}

.BarContainerL {
    background-color: #cccccc;
    width: 35%;
    margin-left: auto;
    margin-right: 2vh;
    text-align: center;
    border-radius: 15px;
    border: 3px solid #808080;
}

.BarContainerR {
    background-color: #cccccc;
    width: 35%;
    margin-right: auto;
    margin-left: 2vh;
    text-align: center;
    border-radius: 15px;
    border: 3px solid #808080;
}

.Experience-Bar-Sub-Container {
    background-color: #808080;
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 8px;
    padding-bottom: 20px;
    border-radius: 14px;
}

.ExperienceBar .C {
    width: 95%;
}

.ExperienceBar .CXX {
    width: 90%;
}

.ExperienceBar .Assembly {
    width: 55%;
}

.ExperienceBar .Python {
    width: 100%;
}

.ExperienceBar .Javascript {
    width: 80%;
}

.ExperienceBar .CS {
    width: 30%;
}

.ExperienceBar .Java {
    width: 35%;
}

.ExperienceBar .Networking {
    width: 75%;
}

.ExperienceBar .TeamworkFlows {
    width: 65%;
}

.ExperienceBar .Databases {
    width: 70%;
}

.ExperienceBar .ML {
    width: 40%;
}

.ExperienceBar .Adaptability {
    width: 80%;
}

.ExperienceBar {
    background-color: #000000;
    border-radius: 15px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
} 

.percentage {
    background-color: #0000ff;
    box-shadow: 0px 0px 10px #000080;
    color: white; 
    padding: 1%; 
    text-align: right; 
    font-size: 14px; 
    font-weight: bold;
    border-radius: 15px;
}