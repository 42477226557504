body {
    background-image: url("/public/background1920x1080.jpg");
    height: auto;
    width: auto;
    background-size: 1920px 1080px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
}

.TitleContainer {
    position: relative;
    text-align: center;
    margin: auto;
    width: 50%;
    border: 3px solid #808080;
    background-color: #cccccc;
    padding: 10px;
}

.InfoContainerS {
    display: flex;
    flex-direction: row;
    background-color: #cccccc;
    width: 60%;
    margin: auto;
    border: 3px solid #808080;
}

.InfoContainerD {
    display: flex;
    flex-direction: row;
    padding: 5%;
}
.InfoSegment {
    text-align: center;
    margin: auto;   
    width: 40%;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 5%;
    border: 3px solid #808080;
    background-color: #cccccc;
}